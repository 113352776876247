import React from 'react';
import styled from 'styled-components';

// styled
import Section from '../styled/Section';
import Wrapper from '../styled/Wrapper';
import Superscript from '../styled/Superscript';

const MailingList = () => (
  <Section squareDetail="left">
    <Wrapper>
      <MailingListContainer>
        <div id="mc_embed_signup">
          <form
            action="https://pauldowman.us4.list-manage.com/subscribe/post?u=a6703a8ede792b529987af54b&amp;id=b261871af8"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mc_embed_signup_scroll">
              <Superscript smaller>Send me updates!</Superscript>
              <div className="mc-field-group">
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  placeholder="Email *"
                  required
                />
              </div>
              <div className="mc-field-group">
                <input
                  type="text"
                  name="FNAME"
                  id="mce-FNAME"
                  placeholder="First name"
                />
              </div>
              <div className="mc-field-group">
                <input
                  type="text"
                  name="LNAME"
                  id="mce-LNAME"
                  placeholder="Last name"
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: 'none' }}
                />
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: 'none' }}
                />
              </div>{' '}
              {/*<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->*/}
              <div
                style={{
                  position: 'absolute',
                  left: '-5000px',
                  visibility: 'hidden',
                }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_a6703a8ede792b529987af54b_b261871af8"
                  tabIndex="-1"
                  value=""
                  readOnly
                />
              </div>
              <div className="clear">
                <input
                  type="submit"
                  value="Subscribe"
                  readOnly
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className="button"
                />
              </div>
            </div>
            <p>
              You can unsubscribe at any time by clicking on the unsubscribe
              link at the bottom of our emails.
            </p>
          </form>
        </div>
      </MailingListContainer>
    </Wrapper>
  </Section>
);

export default MailingList;

const MailingListContainer = styled.div`
  .mc-field-group {
    display: inline-block;
  }
  .clear {
    display: inline-block;
  }
  input[type='submit'] {
    background-color: #fff300 !important;
    color: #131313;
    font-weight: bold;
    border-color: #fff300;
  }
  input {
    background-color: #131313 !important;
    border: 2px solid white;
    color: #fff300;
    padding: 2px 8px;
    display: inline-block;
    margin-right: 24px;
    margin-bottom: 24px;
    :focus {
      border-color: #fff300;
      outline: none;
    }
    ::placeholder {
      color: white;
      opacity: 1;
    }
  }
`;
