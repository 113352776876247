import React from 'react';
import { Link } from 'gatsby';

// components
import Header from '../components/Header';
import SEO from '../components/seo';
import Nav from '../components/Nav';
import Footer from '../components/Footer';

// styled
import Superscript from '../components/styled/Superscript';
import DateText from '../components/styled/DateText';
import Section from '../components/styled/Section';
import Container from '../components/styled/Container';
import Wrapper from '../components/styled/Wrapper';
import Row from '../components/styled/Row';
import Column from '../components/styled/Column';

// sections
import MailingList from '../components/sections/MailingList';

// assets
import TorontoCampfire from '../assets/toronto-campfire.jpg';
import MaRS from '../assets/mars.jpeg';

class Training extends React.Component {
  render() {
    return (
      <Container>
        <SEO title="Location" />
        <Nav selected="/location" />
        <Header />

        <Section dotDetail="left" noPaddingTop noPaddingBottom>
          <Wrapper>
            <Superscript small center>
              Toronto, Canada
            </Superscript>
            <Row style={{ marginTop: 60 }}>
              <Column>
                <div style={{ padding: '0 40px' }}>
                  <img src={TorontoCampfire} />
                </div>
              </Column>
              <Column>
                <p>
                  Toronto is one of the world's top centres for software
                  engineering. Most major tech companies have a presence in
                  Toronto, and there's a very active startup community.{' '}
                  <a href="https://www.cbre.com/research-and-reports/Scoring-Tech-Talent-in-North-America-2018">
                    A recent study
                  </a>{' '}
                  showed that Toronto has created more tech jobs than the San
                  Francisco Bay area, Seattle and Washington, D.C. combined in
                  the last year.{' '}
                  <a href="https://medium.com/@WebSummitHQ/is-toronto-the-new-silicon-valley-3b8047ce32e3">
                    Here's what the Collision / Web Summit folks wrote
                  </a>{' '}
                  about why they decided to move their huge conference to
                  Toronto.
                </p>
                <p>
                  <a href="https://en.wikipedia.org/wiki/Toronto">
                    The metro Toronto area
                  </a>{' '}
                  has a population of almost 6 million (over 9 million with the{' '}
                  <a href="https://en.wikipedia.org/wiki/Golden_Horseshoe">
                    surrounding region
                  </a>
                  ), and it's one of the most diverse cities in North America.
                </p>
                <p>Here's a guide for what to expect:</p>
              </Column>
            </Row>
          </Wrapper>
        </Section>

        <Section noPaddingTop noPaddingBottom squareDetail="right">
          <Wrapper>
            <p>
              <strong>Weather</strong> September is a great time to visit
              Toronto, with daytime temperatures{' '}
              <a href="https://www.google.com/search?&q=toronto+weather+in+september">
                averaging 73°F / 23°C
              </a>
              .
            </p>
            <p>
              <strong>Restaurants</strong> Toronto is a fantastically diverse
              city and the food reflects it. Whether you're looking for{' '}
              <a href="https://www.blogto.com/toronto/the_best_bbq_restaurants_in_toronto/">
                BBQ
              </a>{' '}
              or{' '}
              <a href="https://www.blogto.com/toronto/the_best_vegetarian_restaurants_in_toronto/">
                vegetarian
              </a>
              ,{' '}
              <a href="https://www.blogto.com/toronto/the_best_bone_marrow_in_toronto/">
                bone marrow
              </a>{' '}
              or{' '}
              <a href="https://www.blogto.com/toronto/the_best_vegan_bakeries_in_toronto/">
                vegan snacks
              </a>
              , Toronto has it all. Have you tried{' '}
              <a href="https://www.blogto.com/eat_drink/2019/05/new-bubble-tea-toronto-2019/">
                bubble tea
              </a>
              ,{' '}
              <a href="https://www.blogto.com/toronto/the_best_banh_mi_in_toronto/">
                banh mi
              </a>
              ,{' '}
              <a href="https://www.blogto.com/toronto/the_best_pho_in_toronto/">
                pho
              </a>
              , or{' '}
              <a href="https://www.blogto.com/toronto/the_best_hakka_restaurants_in_toronto/">
                Hakka cuisine
              </a>
              ? And yes, there are{' '}
              <a href="https://www.blogto.com/toronto/the_best_fish_tacos_in_toronto/">
                fish tacos
              </a>{' '}
              too. Here's a list of{' '}
              <a href="https://www.blogto.com/restaurants/?bestOf=1">
                some favorites
              </a>
              .
            </p>
            <p>
              <strong>Nightlife</strong> We'll have a reception after the
              conference, but if you're looking for even more to do while you're
              in town there's no shortage within a short walk.{' '}
              <a href="https://www.blogto.com/toronto/the_best_beer_selection_in_toronto/">
                Beer
              </a>
              ,{' '}
              <a href="https://www.blogto.com/toronto/the_best_cocktails_in_toronto/">
                cocktails
              </a>
              , or{' '}
              <a href="https://www.blogto.com/toronto/the_best_whisky_bars_in_toronto/">
                whiskey
              </a>
              .{' '}
              <a href="https://www.blogto.com/toronto/the_best_jazz_bars_in_toronto/">
                Jazz bars
              </a>{' '}
              or{' '}
              <a href="https://www.blogto.com/eat_drink/2014/06/the_top_20_bars_and_cafes_for_board_games_in_toronto/">
                board games
              </a>
              .
            </p>
            <p>
              <strong>TIFF</strong> The{' '}
              <a href="https://www.tiff.net/tiff/">
                Toronto International Film Festival
              </a>{' '}
              starts on Sept 5! Hollywood and the world's film stars will be in
              Toronto.
            </p>
            <p>
              <strong>More to see and do</strong> You've heard of the{' '}
              <a href="https://www.tripadvisor.ca/Attraction_Review-g155019-d155483-Reviews-CN_Tower-Toronto_Ontario.html">
                CN Tower
              </a>{' '}
              and{' '}
              <a href="https://www.tripadvisor.ca/Attraction_Review-g154998-d186167-Reviews-Niagara_Falls-Niagara_Falls_Ontario.html">
                Niagara Falls
              </a>
              . But there's also a tribute to that favorite Canadian pastime:
              the{' '}
              <a href="https://www.tripadvisor.ca/Attraction_Review-g155019-d155501-Reviews-Hockey_Hall_of_Fame-Toronto_Ontario.html">
                Hockey Hall of Fame
              </a>
              . There's the{' '}
              <a href="https://www.tripadvisor.ca/Attraction_Review-g155019-d5031404-Reviews-Ripley_s_Aquarium_Of_Canada-Toronto_Ontario.html">
                Ripley's Acquarium
              </a>
              , the{' '}
              <a href="https://www.tripadvisor.ca/Attraction_Review-g155019-d187000-Reviews-The_AGO_Art_Gallery_of_Ontario-Toronto_Ontario.html">
                Art Gallery of Ontario
              </a>{' '}
              and{' '}
              <a href="https://www.tripadvisor.ca/Attraction_Review-g155019-d155481-Reviews-Royal_Ontario_Museum-Toronto_Ontario.html">
                the Royal Ontario Musem
              </a>
              .
            </p>
            <p>
              <strong>Best of X</strong> Whatever you're into, from{' '}
              <a href="https://www.blogto.com/toronto/the_best_contemporary_art_galleries_for_emerging_artists_in_toronto/">
                art
              </a>{' '}
              to shopping for{' '}
              <a href="https://www.blogto.com/toronto/the_best_places_to_buy_unique_gifts_in_toronto/">
                gifts
              </a>{' '}
              or{' '}
              <a href="https://www.blogto.com/toronto/best-new-fashion-stores-toronto-2018/">
                fashion
              </a>
              , from <a href="">yoga</a> to{' '}
              <a href="https://www.blogto.com/toronto/the_best_outdoor_skateparks_in_toronto/">
                outdoor skateparks
              </a>
              , you'll find a world-class version of it in Toronto. And BlogTO
              probably has a list for it{' '}
              <a href="https://www.blogto.com/toronto/">here</a>.
            </p>
          </Wrapper>
        </Section>

        <Section noPaddingBottom>
          <Wrapper>
            <Superscript small center>
              The Venue
            </Superscript>
            <Row style={{ marginTop: 60 }}>
              <Column>
                <p>
                  One of the world’s largest urban innovation hubs,{' '}
                  <a href="https://www.marsdd.com/">MaRS Discovery District</a>{' '}
                  helps Canada’s leading science and technology entrepreneurs
                  launch and build successful global companies. MaRS opens
                  markets and offers a range of entrepreneurship programs,
                  market intelligence and business mentorship services – all in
                  an inspiring space that connects people and ideas.
                </p>
                <p>
                  As North America’s largest innovation tech hub, it occupies
                  1.5 million square feet in downtown Toronto. It support a
                  diverse community of more than 120 tenants, including research
                  labs and global tech companies.
                </p>
                <p>
                  <a href="https://goo.gl/maps/2xsMoJNdFS9wKjiV9">
                    MaRS Discovery District, 101 College St, Toronto, ON M5G 1L7
                  </a>
                </p>
                <p>
                  (Note that the <Link to="/training">training</Link> is at a separate venue).
                </p>
              </Column>
              <Column>
                <div style={{ padding: '0 40px' }}>
                  <img src={MaRS} />
                </div>
              </Column>
            </Row>
          </Wrapper>
        </Section>

        <Section noPaddingBottom id="afterparty">
          <Wrapper>
            <Superscript small center>
              The after-party
            </Superscript>
            <p>
              We have organized a great evening event, sponsored by{' '}
              <a href="https://www.cypress.io/">Cypress!</a>&nbsp;
              Join us for food and drinks and great company with the other 
              attendees and speakers after the main conference day.
            </p>
            <p>
              We think a conference should be much more than just great talks,
              it's a chance to make connections with people who are working on,
              or have solved, some of the same challenges as you. This is a
              small enough conference that you will meet any of the speakers and
              attendees that you wish to.
            </p>
            <p>
              We're sure you'll come away with new friends and great memories.
            </p>
            <p>
              The location will be <a href="https://goo.gl/maps/EiEf4xgosJJE2g8N9">Upper Deck by Fran's</a>, which is a 5 minute walk from the venue.
            </p>
            <p>
              Join us for food &amp; drinks starting at <b>6 PM</b>.
            </p>
          </Wrapper>
        </Section>

        <MailingList />

        <Footer />
      </Container>
    );
  }
}

export default Training;
